import react from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";

export const DeleteEventModal = ({
  isOpen,
  afterOpenModal,
  closeModal,
  style,
  contentLabel,
  email_students,
  set_email_students,
  email_parents,
  set_email_parents,
  email_tutors,
  set_email_tutors,
  sms_students,
  set_sms_students,
  sms_parents,
  set_sms_parents,
  sms_tutors,
  set_sms_tutors,
  notes,
  set_notes,
  deleteEventsHandler,
}) => (
  <ReactModal
    isOpen={isOpen}
    onAfterOpen={afterOpenModal}
    onRequestClose={closeModal}
    style={style}
    contentLabel={contentLabel}
  >
    <div className="calendar-modal">
      <div className="close-h add">
        <h4>
          <strong>Are You Sure To Delete ?</strong>
        </h4>
        <button className="closeModal" onClick={closeModal}>
          X
        </button>
      </div>
      <br></br>
      <form name="studentProfile">
        <div className="row d-flex">
          <hr></hr>
          <div className="formbold-input-flex diff">
            <div>
              <div
                className="public_desc_on_calendar"
                style={{ fontSize: "15px" }}
              >
                <input
                  type="checkbox"
                  name="email_students"
                  value="1"
                  checked={email_students}
                  onChange={(e) => set_email_students(e.target.checked)}
                />{" "}
                Email Students
              </div>
            </div>
          </div>
          <div className="formbold-input-flex diff">
            <div>
              <div
                className="public_desc_on_calendar"
                style={{ fontSize: "15px" }}
              >
                <input
                  type="checkbox"
                  name="email_parents"
                  value="1"
                  checked={email_parents}
                  onChange={(e) => set_email_parents(e.target.checked)}
                />{" "}
                Email Parents
              </div>
            </div>
          </div>
          <div className="formbold-input-flex diff">
            <div>
              <div
                className="public_desc_on_calendar"
                style={{ fontSize: "15px" }}
              >
                <input
                  type="checkbox"
                  name="email_tutors"
                  value="1"
                  checked={email_tutors}
                  onChange={(e) => set_email_tutors(e.target.checked)}
                />{" "}
                Email Tutors
              </div>
            </div>
          </div>
          <div className="formbold-input-flex diff">
            <div>
              <div
                className="public_desc_on_calendar"
                style={{ fontSize: "15px" }}
              >
                <input
                  type="checkbox"
                  name="sms_students"
                  value="1"
                  checked={sms_students}
                  onChange={(e) => set_sms_students(e.target.checked)}
                />{" "}
                SMS Students
              </div>
            </div>
          </div>
          <div className="formbold-input-flex diff">
            <div>
              <div
                className="public_desc_on_calendar"
                style={{ fontSize: "15px" }}
              >
                <input
                  type="checkbox"
                  name="sms_parents"
                  value="1"
                  checked={sms_parents}
                  onChange={(e) => set_sms_parents(e.target.checked)}
                />{" "}
                SMS Parents
              </div>
            </div>
          </div>
          <div className="formbold-input-flex diff">
            <div>
              <div
                className="public_desc_on_calendar"
                style={{ fontSize: "15px" }}
              >
                <input
                  type="checkbox"
                  name="sms_tutors"
                  value="1"
                  checked={sms_tutors}
                  onChange={(e) => set_sms_tutors(e.target.checked)}
                />{" "}
                SMS Tutors
              </div>
            </div>
          </div>
          <div className="formbold-input-flex diff">
            <div>
              <div
                className="public_desc_on_calendar"
                style={{ fontSize: "15px" }}
              >
                <label className="formbold-form-label">Notes</label>
                <textarea
                  name="notes"
                  value={notes}
                  className="form-control"
                  onChange={(e) => set_notes(e.target.value)}
                />{" "}
              </div>
            </div>
          </div>

          <div className="formbold-form-btn-wrapper">
            <div className="btn-end">
              <Link className="cancel" onClick={closeModal}>
                Cancel
              </Link>
              <button
                className="cancel"
                style={{ color: "orange" }}
                onClick={(e) => deleteEventsHandler(e, true)}
              >
                Delete This & Future Events
              </button>
              <button
                className="formbold-btn"
                onClick={(e) => deleteEventsHandler(e, false)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ReactModal>
);
