import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserDataContext } from "../../../contextApi/userDataContext";
import ReactModal from "react-modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import payroll from "../users/assets/images/payroll.svg";
import { init } from "i18next";
import AvailabilityForm from "./AvailabilityForm";
import PayrollSettings from "./PayrollSettings";
import AvailabilitySettings from "./AvailabilitySettings";
import MiniSidebar from "../../sidebar/MiniSidebar";
import Sidebar from "../../sidebar/Sidebar";
import TopBar from "../../sidebar/TopBar";
import { fetchData } from "../../../utils/helper";
import GeneralSettings from "./GeneralSetting";
import ProfileCard from "./ProfileCard";
import UpdateAvailability from "./UpdateAvailblity";
import "./css/style.css";
import { API_URL } from "../../../utils/config";
import { availStyles, customStyles, passwordStyles } from "./style";
import PasswordModal from "./PasswordModal";
import ProfileModal from "./ProfileModal";
import { AuthContext } from '../../registerLogin/AuthContext.js';
import { SettingsContext } from '../../../contextApi/SettingsContext.js';

const MyPreferences = () => {
  const {
    userData,
    sidebarToggle,
    userId,
    getAvailabilityData,
    allAvailabilityData,
    setUserData, 
    setUserId
  } = useUserDataContext();
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState('');
  const [profilePicData, setProfilePicData] = useState({});
  const [attendFlag, setAttendFlag] = useState(false);
  const [attendDisabled, setAttenddisabled] = useState(true);
  const [emailDisabled, setEmaildisabled] = useState(true);
  const [availFlag, setAvailFlag] = useState(false);
  const [initial, setInitial] = useState("");
  const [formData, setFormData] = useState({});
  const [tenantData, setTenantData] = useState([]);
  const [availData, setAvailData] = useState({});
  const [days, setDays] = useState({});
  const [updatePass, setUpdatePass] = useState({});
  const [error, setError] = useState({});
  const [editA, setEditA] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [isStudentUpdated, setStudentUpdated] = useState(false);
  const { role } = useContext(AuthContext);
  const { settings, setSettings } = useContext(SettingsContext);


  function getStudentDetails(){
    fetchData(role, setUserData, setUserId, setSettings);
  }

  useEffect(() => {
    getStudentDetails();
    allAvailabilityData();
  }, [isStudentUpdated]);

  useEffect(() => {
    allAvailabilityData();
  }, []);

  useEffect(() => {
    var name = `${userData.first_name}${" "}${userData.last_name}`;

    var parts = name.split(" ");
    var initials = "";
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    setInitial(initials);
    formData.first_name = userData?.first_name;
    formData.last_name = userData?.last_name;
    formData.email = userData?.email;
    formData.phone = userData?.phone;
    setProfilePhoto(userData?.business_data?.dp_url);
    formData.title = userData?.business_data?.business_name;
    tenantData.address = userData?.business_data?.address;
    tenantData.virtual_meeting = userData?.business_data?.virtual_meeting;
    tenantData.subjects = userData?.business_data?.subjects;
    tenantData.overdue_attendence = userData?.business_data?.overdue_attendence;
    tenantData.default_notes_view = userData?.business_data?.default_notes_view;
    tenantData.copy_recent_event = userData?.business_data?.copy_recent_event;
    tenantData.automatically_copy_lesson =
      userData?.business_data?.automatically_copy_lesson;
    tenantData.student_register_lesson =
      userData?.business_data?.student_register_lesson;
    tenantData.student_cancel_lesson =
      userData?.business_data?.student_cancel_lesson;
    tenantData.parent_student_signup =
      userData?.business_data?.parent_student_signup;
    tenantData.parent_student_disable_email_reminder =
      userData?.business_data?.parent_student_disable_email_reminder;
    tenantData.allow_student_email_studylog =
      userData?.business_data?.allow_student_email_studylog;
    tenantData.daily_agenda = userData?.business_data?.daily_agenda;
  }, [userData]);

  function openModal(e) {
    setIsOpen(e);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal(e) {
    setIsOpen(e);
  }

  const handleAttendEdit = (e) => {
    setAttendFlag(!e.target.value);
    setAttenddisabled(false);
  };

  const handleEmailEdit = (e) => {
    setAttendFlag(!e.target.value);
    setEmaildisabled(false);
  };
  const handleCancelAttendFlag = (e) => {
    setAttendFlag(false);
    setAttenddisabled(true);
    setEmaildisabled(true);
  };
  const handleChange = async (e) => {
    const name = e.target.name;
    let value = e.target.value;
    // console.log(name, value);
    if (
      name === "title" ||
      name === "first_name" ||
      name === "last_name" ||
      name === "email" ||
      name === "phone"
    ) {
      setFormData({ ...formData, [name]: value });
    } else {
      if (
        name === "overdue_attendence" ||
        name === "automatically_copy_lesson" ||
        name === "student_register_lesson" ||
        name === "student_cancel_lesson" ||
        name === "parent_student_disable_email_reminder" ||
        name === "allow_student_email_studylog" ||
        name === "parent_student_signup"
      ) {
        if (e.target.checked) {
          value = "true";
        } else {
          value = null;
        }
      }
      setTenantData({ ...tenantData, [name]: value });
    }

    if (name === "file") {
      profilePicData["file"] = e.target.files[0];
      profilePicData["user_id"] = userId;
      const config = {
        method: "POST",
        url: `${API_URL}update-dp`,
        headers: {
          "content-type": "multipart/form-data",
        },
        data: profilePicData,
      };
      await axios(config)
        .then((response) => {
          sessionStorage.setItem("user_profile",response.data.data.dp_url);
          setProfilePhoto(response.data.data.dp_url);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.success === false) {
            setError(error.response.data.data);
          }
        });
    }
  };

  const formSubmit = async (e) => {
    formData["user_id"] = userId;
    // if (profilePhoto) {
    //   formData["photo"] = profilePhoto;
    // }
    formData["tenant_data"] = tenantData;

    console.log(formData);

    e.preventDefault();
    const config = {
      method: "PATCH",
      url: `${API_URL}savedata`,
      data: formData,
    };
    await axios(config)
      .then((response) => {
        // console.log(response);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        sessionStorage.setItem("user_name",response.data.data.userinfo.first_name);
        setStudentUpdated(true);
        setAttendFlag(false);
        setAvailFlag(false);
        setAttenddisabled(true);
        setEmaildisabled(true);
        setIsOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePassword = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUpdatePass({ ...updatePass, [name]: value });
  };

  const formSubmitPassword = async (e) => {
    updatePass["user_id"] = userData.id;

    console.log(updatePass);
    e.preventDefault();
    const config = {
      method: "PATCH",
      url: `${API_URL}update-password`,
      data: updatePass,
    };
    await axios(config)
      .then((response) => {
        console.log(response);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        closeModal(); // or setModalIsOpen(false);
        setAttendFlag(false);
        setAvailFlag(false);
        setAttenddisabled(true);
        setEmaildisabled(true);
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.data.success === false) {
          setError(error.response.data.data.error);
        }
        // console.log(error.response.data.data.error);
      });
  };

  const handleAvailChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (
      name === "sun" ||
      name === "mon" ||
      name === "tue" ||
      name === "wed" ||
      name === "thu" ||
      name === "fri" ||
      name === "sat"
    ) {
      setDays({ ...days, [name]: value });
    } else {
      setAvailData({ ...availData, [name]: value });
    }
  };
  const handleAvailChangePopup = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (
      name === "sun" ||
      name === "mon" ||
      name === "tue" ||
      name === "wed" ||
      name === "thu" ||
      name === "fri" ||
      name === "sat"
    ) {
      const index = selectedDays.indexOf(value);
      if (index > -1) {
        selectedDays.splice(index, 1);
      } else {
        selectedDays.push(value);
      }
      console.log(selectedDays);

      setDays({ ...selectedDays });
    } else {
      setAvailData({ ...availData, [name]: value });
    }
  };

  const formAvailSubmit = async () => {
    availData["user_id"] = userData.id;
    let arr = Object.values(days);
    let allday = arr.toString();
    availData["days"] = allday;

    console.log(availData);

    const config = {
      method: "POST",
      url: `${API_URL}add-availability`,
      data: availData,
    };
    await axios(config)
      .then((response) => {
        console.log(response);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setAvailFlag(false);
        allAvailabilityData();
      })
      .catch((error) => {
        console.log(error.response.data.data);
        if (error.response.data.success === false) {
          setError(error.response.data.data);
        }
      });
  };

  const deleteAvailability = async (id) => {
    const config = {
      method: "DELETE",
      url: `${API_URL}delete-availability/${id}`,
    };
    console.log(id);
    await axios(config)
      .then((response) => {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        allAvailabilityData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const editAvailability = async (id) => {
    openModal("updateAvail");

    const config = {
      method: "GET",
      url: `${API_URL}get-availability/${id}`,
    };
    await axios(config)
      .then((response) => {
        console.log(response.data.data);
        setEditA(response.data.data);
        availData["start_date"] = response.data.data.start_date;
        availData["end_date"] = response.data.data.end_date;
        availData["start_time"] = response.data.data.start_time;
        availData["end_time"] = response.data.data.end_time;
        availData["note"] = response.data.data.note;
        days["days"] = JSON.parse(response.data.data.days);
        setSelectedDays(JSON.parse(response.data.data.days));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateAvailability = async (e) => {
    let arr = Object.values(days);
    let allday = arr.toString();
    availData["days"] = allday;
    availData["id"] = editA.id;
    e.preventDefault();
    // console.log(availData);
    const config = {
      method: "PATCH",
      url: `${API_URL}update-availability`,
      data: availData,
    };
    await axios(config)
      .then((response) => {
        console.log(response.data);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        // setIsOpen(false);
        closeModal();
        allAvailabilityData();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.success === false) {
          setError(error.response.data.data);
        }
      });
  };

  return (
    <div className="wrapper">
      {sidebarToggle ? (
        <>
          <MiniSidebar />
        </>
      ) : (
        <>
          <Sidebar />
        </>
      )}

      <div className="main">
        <TopBar />

        <ProfileModal
        isOpen= {modalIsOpen === "profile"} 
        onAfterOpen ={afterOpenModal}
        onRequestClose ={closeModal} 
        style= { customStyles} 
        contentLabel={"Example Modal"} 
        profilePhoto ={ profilePhoto} 
        error={error} 
        handleChange={handleChange} 
        formData={formData} 
        tenantData={tenantData}
        formSubmit ={formSubmit}
        initial = {initial}
        />
        <PasswordModal
        isOpen={modalIsOpen === "password"}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={passwordStyles}
        contentLabel="Change Password"
        handleChange={handleChangePassword}
        handleSubmit = {formSubmitPassword}
        error = {error}
        /> 
        <UpdateAvailability
        isOpen={modalIsOpen === "updateAvail"}
        afterOpenModal={afterOpenModal}
        closeModal={closeModal}
        style={availStyles} // Add your styles here
        contentLabel="Update Availability"
        title="Update Availability"
        selectedDays={selectedDays}
        error={error}
        availData={availData}
        handleAvailChangePopup={handleAvailChangePopup}
        updateAvailability={updateAvailability} 
        />

        <main className="content">
          <ToastContainer />
          <div className="container-fluid p-0">
            <div className="row d-flex">
              <div className="col-xl-4 col-xxl-4">
                <ProfileCard
                  profilePhoto={profilePhoto}
                  initial={initial}
                  userData={userData}
                  openModal={openModal}
                />
                {/* <div className="card">
                  <div className="card-body default-set">
                    <h3>Default Settings</h3>
                    <div className="attendance-user">
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </div>
                    <h5>
                      <strong>Default Lesson Category</strong>
                    </h5>
                    <span>Lesson</span>
                    <h5>
                      <strong>Default Lesson Duration</strong>
                    </h5>
                    <span>30 Minutes</span>
                    <h5>
                      <strong>Default Billing</strong>
                    </h5>
                    <span>
                      Student pays based on the number of lessons taken
                    </span>
                    <h5>
                      <strong>Default Lesson Price</strong>
                    </h5>
                    <span>₹ 30.00</span>
                  </div>
                </div> */}
              </div>
              <div className="col-xl-8 col-xxl-8">
                <GeneralSettings
                  tenantData={tenantData}
                  attendDisabled={attendDisabled}
                  emailDisabled = {emailDisabled}
                  handleChange={handleChange}
                  handleAttendEdit={handleAttendEdit}
                  attendFlag={attendFlag}
                  handleCancelAttendFlag={handleCancelAttendFlag}
                  formSubmit={formSubmit}
                  handleEmailEdit={handleEmailEdit}
                />
                <AvailabilitySettings
                  getAvailabilityData={getAvailabilityData}
                  availFlag={availFlag}
                  setAvailFlag={setAvailFlag}
                  handleAvailChange={handleAvailChange}
                  handleAvailChangePopup={handleAvailChangePopup}
                  formAvailSubmit={formAvailSubmit}
                  editAvailability={editAvailability}
                  deleteAvailability={deleteAvailability}
                  availData={availData}
                  error={error}
                />
                <PayrollSettings />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MyPreferences;
