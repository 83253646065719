import React from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';

/**
 * ProfileModal Component
 * 
 * @param {object} props - Component props
 * @param {boolean} props.isOpen - Indicates whether the modal is open
 * @param {function} props.onAfterOpen - Function to call after the modal opens
 * @param {function} props.onRequestClose - Function to call to request closing the modal
 * @param {object} props.style - Custom styles for the modal
 * @param {string} props.contentLabel - Label for the modal
 * @param {string} props.profilePhoto - URL of the profile photo
 * @param {object} props.error - Object containing any error messages
 * @param {function} props.handleChange - Function to handle input changes
 * @param {object} props.formData - Object containing form data for the profile
 * @param {object} props.tenantData - Object containing tenant data
 * @param {function} props.formSubmit - Function to handle form submission
 * @param {string} props.initial - Initials to display if no profile photo is available
 */
const ProfileModal = ({
  isOpen,
  onAfterOpen,
  onRequestClose,
  style,
  contentLabel,
  profilePhoto,
  error,
  handleChange,
  formData,
  tenantData,
  formSubmit,
  initial
}) => (
  <ReactModal
    isOpen={isOpen}
    onAfterOpen={onAfterOpen}
    onRequestClose={onRequestClose}
    style={style}
    contentLabel={contentLabel}
  >
    <div className="mypreference-modal">
      {/* Modal header */}
      <div className="close-h">
        <h4>Edit Profile</h4>
        <button className="closeModal" onClick={onRequestClose}>
          X
        </button>
      </div>

      {/* Modal form */}
      <form name="studentProfile">
        <div className="row d-flex">
          {/* Profile photo section */}
          <div className="col-xl-4 col-xxl-4">
            <div className="formbold-input-flex justify-content-center">
              <div className="student-profile-view">
                <label htmlFor="photo" className="formbold-form-label">
                  Photo <span>Optional</span>
                </label>
                <div className="initials py-3">
                  <div className="image-user">
                    {profilePhoto ? (
                      <img src={profilePhoto} alt="Profile" />
                    ) : (
                      <h2>{initial && initial.toLocaleUpperCase()}</h2>
                    )}
                  </div>
                </div>
                <div className="text-center pb-2">
                  <small className="input-error-message">
                    {error?.file?.length ? error.file[0] : null}
                  </small>
                </div>
                <input
                  type="file"
                  name="file"
                  className="form-control b-none"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* Profile information section */}
          <div className="col-xl-8 col-xxl-8">
            <div className="formbold-form-step-1 active">
              {/* Title input */}
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="title" className="formbold-form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Name inputs */}
              <div className="formbold-input-flex">
                <div>
                  <label htmlFor="first_name" className="formbold-form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="last_name" className="formbold-form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    className="form-control"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              {/* Email and phone inputs */}
              <div className="formbold-input-flex">
                <div>
                  <label htmlFor="email" className="formbold-form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="formbold-form-label">
                    Phone Number <span>Optional</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Address input */}
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="address" className="formbold-form-label">
                    Address <span>Optional</span>
                  </label>
                  <textarea
                    name="address"
                    className="form-control"
                    value={tenantData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Virtual meeting input */}
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="virtual_meeting" className="formbold-form-label">
                    Virtual Meeting <span>Optional</span>
                    <br />
                    <span>
                      Share a link to Zoom, Google Meet, or any other
                      video conferencing application.
                    </span>
                  </label>
                  <input
                    type="text"
                    name="virtual_meeting"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Subjects input */}
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="subjects" className="formbold-form-label">
                    Subjects <span>Optional</span>
                    <br />
                    <span>
                      Use a semicolon or press the Enter key to separate entries
                    </span>
                  </label>
                  <input
                    type="text"
                    name="subjects"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Preferred location input */}
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="location" className="formbold-form-label">
                    Preferred Location
                  </label>
                  <select
                    name="location"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option>First Available Location</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Form submit and cancel buttons */}
          <hr />
          <div className="formbold-form-btn-wrapper">
            <div className="btn-end">
              <Link className="cancel" onClick={onRequestClose}>
                Cancel
              </Link>
              <button className="formbold-btn" onClick={formSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ReactModal>
);

export default ProfileModal;
