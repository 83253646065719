import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import Select from "react-select";
import DayTabInput from "../../../form/day-tab-input/DayTabInput";


export const QuickAddLessonModal = ({
    isOpen,
    afterOpenModal,
    closeModal,
    style,
    contentLabel,
    isEditForm,
    event_name,
    set_event_name,
    errors,
    event_tutor,
    getStudentsByTutorIdHandler,
    allTutors,
    event_attendees_value,
    set_event_attendees,
    studentsList,
    colourStyles,
    location_id,
    set_location_id,
    allLocation,
    start_date,
    formatDate,
    set_start_date,
    end_date,
    set_end_date,
    start_time,
    set_start_time,
    end_time,
    set_end_time,
    event_recurring,
    set_event_recurring,
    event_frequency,
    set_event_frequency,
    event_repeat_on,
    set_event_repeat_on,
    event_frequency_val,
    set_event_frequency_val,
    event_repeat_until,
    set_event_repeat_until,
    event_repeat_indefinitely,
    set_event_repeat_indefinitely,
    is_public,
    set_is_public,
    require_makeup_credits,
    set_require_makeup_credits,
    saveAllEvents,
    saveOneEvents
}) =>(
    <ReactModal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={style}
            contentLabel={contentLabel}
        >
          <div className="calendar-modal">
            <div className="close-h add">
              <h4>
                <strong>
                  {isEditForm ? "Edit Calendar Event" : "Quick Add Lesson"}
                </strong>
              </h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <br></br>
            <form name="studentProfile">
              <div className="row d-flex">
                <div className="col-xl-12 col-xxl-12">
                  <div className="formbold-form-step-1 active">
                    <div className="formbold-input-flex diff">
                      <div>
                        <label htmlFor="tutor" className="formbold-form-label">
                          Title
                        </label>
                        <div>
                          <input
                            type="text"
                            name="event_name"
                            className="form-control"
                            value={event_name}
                            onChange={(e) => set_event_name(e.target.value)}
                          />
                          {errors.event_name && <small className="input-error-message">{errors.event_name[0]}</small>}
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <label htmlFor="tutor" className="formbold-form-label">
                          Tutor
                        </label>
                        <div>
                          <select
                            name="tutor"
                            className="form-control"
                            onChange={(e) =>
                              getStudentsByTutorIdHandler(e.target.value)
                            }
                            value={event_tutor}
                          >
                            <option value="">Select Tutor</option>
                            {allTutors.map((item) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="student"
                          className="formbold-form-label"
                        >
                          Student
                        </label>
                        <div>
                          <Select
                            defaultValue={event_attendees_value}
                            onChange={(e) => set_event_attendees(e)}
                            isMulti={true}
                            options={studentsList}
                            styles={colourStyles}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <label
                          htmlFor="location"
                          className="formbold-form-label"
                        >
                          Location
                        </label>
                        <select
                          onChange={(e) => set_location_id(e.target.value)}
                          value={location_id}
                          className="form-control"
                          name="location"
                        >
                          <option value={""}>Choose</option>
                          {allLocation.map((e) => {
                            return (
                              <option value={e.id}>{e.eventloc_name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <label
                          htmlFor="start_date"
                          className="formbold-form-label"
                        >
                          Date
                        </label>
                        <input
                          type="date"
                          name="start_date"
                          className="form-control"
                          value={formatDate(start_date)}
                          onChange={(e) => set_start_date(e.target.value)}
                        />
                      </div>
                      {/* <div>
                        <div>
                          <label htmlFor="date" className="formbold-form-label">
                            End Date
                          </label>
                          <input
                            type="date"
                            name="end_date"
                            className="form-control"
                            value={formatDate(end_date)}
                            onChange={(e) => set_end_date(e.target.value)}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <label
                          htmlFor="start_time"
                          className="formbold-form-label"
                        >
                          Start Time
                        </label>
                        <br></br>

                        <input
                          type="time"
                          name="start_time"
                          className="form-control"
                          value={start_time}
                          onChange={(e) => set_start_time(e.target.value)}
                        />
                        {errors.start_time && <small className="input-error-message">{errors.start_time[0]}</small>}
                      </div>
                      <div>
                        <label
                          htmlFor="end_time"
                          className="formbold-form-label"
                        >
                          End Time
                        </label>
                        <br></br>

                        <input
                          type="time"
                          name="end_time"
                          className="form-control"
                          value={end_time}
                          onChange={(e) => set_end_time(e.target.value)}
                        />
                        {errors.end_time && <small className="input-error-message">{errors.end_time[0]}</small>}
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <div
                          className="preference"
                          style={{ fontSize: "15px" }}
                        >
                          <input
                            type="checkbox"
                            name="event_repeats"
                            value={event_recurring}
                            checked={event_recurring}
                            onChange={(e) =>
                              set_event_recurring(e.target.checked)
                            }
                          />
                          This event repeats
                        </div>
                      </div>
                    </div>
                    {event_recurring && (
                      <>
                        <div className="recurring">
                          <div className="recurring-head">
                            <i class="fa fa-undo" aria-hidden="true"></i>{" "}
                            <strong>Recurring Event</strong>
                          </div>

                          <div className="formbold-input-flex diff">
                            <div>
                              <div>
                                <label
                                  htmlFor="frequency"
                                  className="formbold-form-label"
                                >
                                  Frequency
                                </label>
                              </div>
                              <div className="input-radio">
                                <input
                                  type="radio"
                                  value="Daily"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Daily"}
                                ></input>
                                Daily
                                <input
                                  type="radio"
                                  value="Weekly"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Weekly"}
                                ></input>
                                Weekly
                                <input
                                  type="radio"
                                  value="Monthly"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Monthly"}
                                ></input>
                                Monthly
                                <input
                                  type="radio"
                                  value="Yearly"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Yearly"}
                                ></input>
                                Yearly
                              </div>
                              {event_frequency == "Daily" && (
                                <DayTabInput
                                  values={event_repeat_on}
                                  setDaysValue={set_event_repeat_on}
                                />
                              )}
                            </div>
                          </div>
                          {event_frequency != "Daily" && (
                            <div className="formbold-input-flex align-items-end">
                              <div>
                                <label
                                  htmlFor="time"
                                  className="formbold-form-label"
                                >
                                  Every
                                </label>
                                <br></br>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    name="every"
                                    className="form-control"
                                    style={{
                                      paddingLeft: "25px",
                                      paddingRight: "70px",
                                    }}
                                    value={event_frequency_val}
                                    min={1}
                                    max={100}
                                    onChange={(e) =>
                                      set_event_frequency_val(e.target.value)
                                    }
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                    }}
                                  >
                                    {event_frequency === "Daily" ||
                                    event_frequency === "Weekly"
                                      ? "Weeks"
                                      : event_frequency}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          {!event_repeat_indefinitely && (
                            <div>
                              <label
                                htmlFor="time"
                                className="formbold-form-label"
                              >
                                Repeat Until
                              </label>
                              <br></br>
                              <input
                                type="date"
                                name="repeat_until"
                                className="form-control"
                                value={event_repeat_until}
                                onChange={(e) =>
                                  set_event_repeat_until(
                                    formatDate(e.target.value)
                                  )
                                }
                              />{" "}
                            </div>
                          )}
                          <div className="formbold-input-flex">
                            <div>
                              <div
                                className="preference"
                                style={{ fontSize: "15px" }}
                              >
                                <input
                                  type="checkbox"
                                  name="repeats_indefinitely"
                                  value=""
                                  onChange={(e) =>
                                    set_event_repeat_indefinitely(
                                      e.target.checked
                                    )
                                  }
                                  checked={event_repeat_indefinitely}
                                />
                                Repeat indefinitely
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="formbold-input-flex diff">
                      <div>
                        <div>
                          <label
                            htmlFor="visibility"
                            className="formbold-form-label"
                          >
                            Visibility
                          </label>
                        </div>
                        <div className="input-radio">
                          <input
                            type="radio"
                            value="1"
                            name="quick_add_visibility"
                            onChange={(e) => set_is_public(e.target.value)}
                            checked={is_public == "1" ? true : false}
                          ></input>
                          Public - Visible on the Student Portal calendar to all
                          students
                        </div>
                        <div className="input-radio">
                          <input
                            type="radio"
                            value="0"
                            name="quick_add_visibility"
                            checked={is_public == "0" ? true : false}
                            onChange={(e) => set_is_public(e.target.value)}
                          ></input>
                          Private - Visible on the Student Portal calendar to
                          current attendees only
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <div
                          className="preference"
                          style={{ fontSize: "15px" }}
                        >
                          <input
                            type="checkbox"
                            name="quickadd_event_credit"
                            value={require_makeup_credits}
                            checked={require_makeup_credits}
                            onChange={(e) =>
                              set_require_makeup_credits(e.target.checked)
                            }
                          />
                          This event requires a make-up credit
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="formbold-form-btn-wrapper">
                  <div className="btn-end">
                    <Link className="cancel" onClick={closeModal}>
                      Cancel
                    </Link>
                    {isEditForm && event_recurring && (
                      <button
                        className="cancel"
                        onClick={(e) => saveAllEvents(e)}
                      >
                        Save This & Future Events
                      </button>
                    )}
                    <button
                      className="formbold-btn"
                      onClick={(e) => saveOneEvents(e)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
    </ReactModal>
)