import { format } from 'date-fns';

// Mapping of PHP date format specifiers to JS date-fns compatible formats
const phpToJsFormatMap = {
  'd': 'dd',
  'D': 'EEE',
  'j': 'd',
  'l': 'EEEE',
  'F': 'MMMM',
  'm': 'MM',
  'Y': 'yyyy',
  'y': 'yy'
};

// Method to convert PHP date format to JS-compatible date format
export const convertPhpToJsDateFormat = (phpDateFormat) => {
  // Check if the phpDateFormat is null, undefined, or an empty string
  if (!phpDateFormat || typeof phpDateFormat !== 'string') {
    console.error('Invalid PHP date format:', phpDateFormat);
    return null; // Return null or default format if invalid
  }

  let jsDateFormat = phpDateFormat;

  // Replace each PHP format specifier with the corresponding JS one
  Object.keys(phpToJsFormatMap).forEach(phpFormat => {
    if (phpFormat === 'n') {
      // Ignore or replace 'n' with a valid JS format or just remove it
      jsDateFormat = jsDateFormat.replace(new RegExp(`\\bn\\b`, 'g'), '');
    } else {
      const jsFormat = phpToJsFormatMap[phpFormat];
      const regExp = new RegExp(`\\b${phpFormat}\\b`, 'g'); // Use word boundary to ensure full match
      jsDateFormat = jsDateFormat.replace(regExp, jsFormat);
    }
  });

  console.log(`Converted JS format: ${jsDateFormat}`); // Debugging line to check the conversion
  return jsDateFormat;
};

// Method to format date according to the provided or default format
export const displayFormattedDate = (dateValue, dateFormat = null) => {
  if (!dateValue || isNaN(new Date(dateValue).getTime())) {
    console.error('Invalid or null date value:', dateValue);
    return ''; // Return an empty string or a default value
  }
  try {
    const date = new Date(dateValue);

    if (dateFormat && dateFormat !== null && typeof dateFormat !== undefined) {
      const jsDateFormat = convertPhpToJsDateFormat(dateFormat);
      return format(date, jsDateFormat);
    }

    return dateValue; // Return the original date if no format is provided or invalid
  } catch (error) {
    console.error('Invalid date or format:', error);
    return dateValue;
  }
};