import React, { useState, useRef } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './style.css'

const LessonDetails = ({
  email_student,
  set_email_student,
  email_tutor,
  set_email_tutor,
  student_notes,
  setStudentNotes,
  parent_notes,
  setParentNotes,
  private_notes,
  setPrivateNotes
}) => {
  const editorRef = useRef(null);
  const [selectedNoteType, setSelectedNoteType] = useState('private_notes');

  const handleSave = () => {
    if (editorRef.current) {
      const data = editorRef.current.getData();
      console.log(data);
    }
  };

  return (
    <div className="lesson-details">
      <p>Add notes, assignments, and link online resources</p>

      <select 
        value={selectedNoteType} 
        onChange={(e) => setSelectedNoteType(e.target.value)}
      >
        <option value="private_notes">Private Notes</option>
        <option value="parent_notes">Parent Notes</option>
        <option value="student_notes">Student Notes</option>
      </select>

      {selectedNoteType === "student_notes" && (
        <CKEditor
          editor={ClassicEditor}
          data={student_notes}
          onChange={(event, editor) => {
            const data = editor.getData();
            setStudentNotes(data);
          }}
          onReady={(editor) => {
            if (editorRef) editorRef.current = editor;
          }}
          config={{
            toolbar: [
              'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
              'imageUpload', 'indent', 'outdent', 'undo', 'redo'
            ],
            image: {
              toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
            },
          }}
        />
      )}

      {selectedNoteType === "parent_notes" && (
        <CKEditor
          editor={ClassicEditor}
          data={parent_notes}
          onChange={(event, editor) => {
            const data = editor.getData();
            setParentNotes(data)
          }}
          onReady={(editor) => {
            if (editorRef) editorRef.current = editor;
          }}
          config={{
            toolbar: [
              'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
              'imageUpload', 'indent', 'outdent', 'undo', 'redo'
            ],
            image: {
              toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
            },
          }}
        />
      )}

      {selectedNoteType === "private_notes" && (
        <CKEditor
          editor={ClassicEditor}
          data={private_notes}
          onChange={(event, editor) => {
            const data = editor.getData();
            setPrivateNotes(data)
          }}
          onReady={(editor) => {
            if (editorRef) editorRef.current = editor;
          }}
          config={{
            toolbar: [
              'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
              'imageUpload', 'indent', 'outdent', 'undo', 'redo'
            ],
            image: {
              toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
            },
          }}
        />
      )}

      <div className="email-outer-box">
        <label className ="lessondetails-navwrapper">
          <input
            type="checkbox"
            checked={email_student}
            onChange={(e) => set_email_student(e.target.checked)}
            name="email_student"
          />
          <span className = "lessons-email-wrapper">Email Students</span>
        </label>
        <label className ="lessondetails-navwrapper">
          <input
            type="checkbox"
            checked={email_tutor}
            onChange={(e) => set_email_tutor(e.target.checked)}
            name="email_tutor"
          />
          <span className = "lessons-email-wrapper" >Email Tutor</span>
        </label>
      </div>
    </div>
  );
};

export default LessonDetails;

