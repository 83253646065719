import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import Select from "react-select";
import DayTabInput from "../../../form/day-tab-input/DayTabInput";


export const NewEventModal = ({
    isOpen,
  afterOpenModal,
  closeModal,
  style,
  contentLabel,
  isEditForm,
  event_name,
  set_event_name,
  event_tutor,
  getStudentsByTutorIdHandler,
  allTutors,
  showSubstituteTutor,
  setShowSubstituteTutor,
  event_subtutor,
  set_event_subtutor,
  filteredTutors,
  is_public,
  set_is_public,
  require_makeup_credits,
  set_require_makeup_credits,
  allow_registration,
  set_allow_registration,
  othersStudents,
  handleCheckboxChange,
  event_attendees,
  set_event_attendees,
  event_attendees_value,
  studentsList,
  colourStyles,
  groupStudents,
  handleGroupChange,
  selectedGroups,
  eventcat_id,
  set_eventcat_id,
  categoriesList,
  location_id,
  set_location_id,
  allLocation,
  start_date,
  formatDate,
  set_start_date,
  start_time,
  set_start_time,
  duration,
  setDuration,
  calculateEndDateTimeByDuration,
  event_all_day,
  set_event_all_day,
  event_recurring,
  set_event_recurring,
  event_frequency,
  set_event_frequency,
  event_repeat_on,
  set_event_repeat_on,
  event_frequency_val,
  set_event_frequency_val,
  event_repeat_until,
  set_event_repeat_until,
  event_repeat_indefinitely,
  set_event_repeat_indefinitely,
  student_pricing,
  set_student_pricing,
  student_pricing_val,
  set_student_pricing_val,
  event_public_desc,
  set_event_public_desc,
  event_private_desc,
  set_event_private_desc,
  errors,
  saveOneEvents,
  saveAllEvents,
  selectedCurrency
})=>(
    <ReactModal
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={style}
          contentLabel={contentLabel}
        >
          <div className="calendar-modal">
            <div className="close-h add">
              <h4>
                <strong>
                  {isEditForm ? "Edit Calendar Event" : "New Calendar Event"}
                </strong>
              </h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <br></br>
            <form name="studentProfile">
              <div className="row d-flex">
                <div className="col-xl-12 col-xxl-12">
                  <div className="formbold-form-step-1 active">
                    <div className="formbold-input-flex diff">
                      <div>
                        <label htmlFor="tutor" className="formbold-form-label">
                          Title
                        </label>
                        <div>
                          <input
                            type="text"
                            name="event_name"
                            className="form-control"
                            value={event_name}
                            onChange={(e) => set_event_name(e.target.value)}
                          />
                          {errors.event_name && <small className="input-error-message">{errors.event_name[0]}</small>}
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex align-items-end">
                      <div>
                        <label htmlFor="tutor" className="formbold-form-label">
                          Tutor
                        </label>
                        <div>
                          <select
                            name="tutor"
                            className="form-control"
                            onChange={(e) =>
                              getStudentsByTutorIdHandler(e.target.value)
                            }
                            value={event_tutor}
                          >
                            <option value="">Select Tutor</option>

                            {allTutors && allTutors.length > 0 ? (
                              allTutors.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))
                            ) : (
                              <option value="">No tutor available</option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div>
                        <div
                          className="preference"
                          style={{ fontSize: "15px" }}
                        >
                          <input
                            type="checkbox"
                            name="add_substitute_tutor"
                            value="Add substitute tutor"
                            onChange={(e) =>
                              setShowSubstituteTutor(e.target.checked)
                            }
                            checked={showSubstituteTutor}
                          />
                          Add substitute tutor
                        </div>
                      </div>
                    </div>
                    {showSubstituteTutor && (
                      <div className="formbold-input-flex">
                        <div>
                          <label
                            htmlFor="Substitute Tutor"
                            className="formbold-form-label"
                          >
                            Substitute Tutor
                          </label>
                          <div>
                            <select
                              name="substitute_tutor"
                              className="form-control"
                              onChange={(e) =>
                                set_event_subtutor(e.target.value)
                              }
                              value={event_subtutor}
                              id="substitute_tutor"
                            >
                              {filteredTutors &&
                                filteredTutors.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="formbold-input-flex diff">
                      <div>
                        <div>
                          <label
                            htmlFor="default_notes_view"
                            className="formbold-form-label"
                          >
                            Visibility
                          </label>
                        </div>
                        <div className="input-radio">
                          <input
                            type="radio"
                            name="quick_add_visibility"
                            value={"1"}
                            onChange={(e) => set_is_public(e.target.value)}
                            checked={is_public == "1" ? true : false}
                          ></input>
                          Public - Visible on the Student Portal calendar to all
                          students
                        </div>
                        <div className="input-radio">
                          <input
                            type="radio"
                            value="0"
                            name="quick_add_visibility"
                            checked={is_public == "0" ? true : false}
                            onChange={(e) => set_is_public(e.target.value)}
                          ></input>
                          Private - Visible on the Student Portal calendar to
                          current attendees only
                        </div>
                      </div>
                    </div>
                    <div class="input-check">
                      <input
                        type="checkbox"
                        name="student_makeup_credit"
                        checked={require_makeup_credits}
                        onChange={(e) =>
                          set_require_makeup_credits(e.target.checked)
                        }
                      />
                      This event requires a make-up credit
                    </div>
                    <div class="input-check">
                      <input
                        type="checkbox"
                        name="student_register_lesson"
                        checked={allow_registration}
                        onChange={(e) =>
                          set_allow_registration(e.target.checked)
                        }
                      />
                      Allow students to register through the Student Portal
                    </div>
                    <hr></hr>
                    <div className="mb-2">
                      <div className="formbold-input-flex diff mb-0">
                        <div style={{ position: 'relative' }}>
                          <label htmlFor="attendees" className="formbold-form-label">
                            Attendees
                          </label>
                          <div>
                            <Select
                              defaultValue={event_attendees_value}
                              value={event_attendees}
                              onChange={(e) => set_event_attendees(e)}
                              isMulti={true}
                              options={studentsList}
                              styles={colourStyles}
                            />
                          </div>
                          <div className="rightDropdown">
                            <div className="dropdown">
                              <button className="dropbtn" type="button">
                                Groups <i className="fas fa-chevron-down"></i>
                              </button>
                              <div className="dropdown-content">
                                {groupStudents.map(item => (
                                  <label
                                    key={item.id}
                                    value={item.name}
                                    onClick={() => handleGroupChange(item.id, item.students)}
                                    className={selectedGroups.includes(item.id) ? 'selected' : ''}
                                  >
                                    {item.name}
                                  </label>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="input-check p-1">
                        <input
                          type="checkbox"
                          name="student_other_tutors"
                          checked={othersStudents}
                          onChange={handleCheckboxChange}
                        />
                        Select attendees from other tutors
                      </div>
                    </div>

                    <div className="formbold-input-flex">
                      <div>
                        <label
                          htmlFor="category"
                          className="formbold-form-label"
                        >
                          Category
                        </label>
                        <br></br>

                        <select
                          name="category"
                          className="form-control"
                          value={eventcat_id}
                          onChange={(e) => set_eventcat_id(e.target.value)}
                          id="category"
                        >
                          <option value={""}>Choose</option>
                          {categoriesList?.map((e) => {
                            return (
                              <option value={e?.id}>{e?.eventcat_name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="location"
                          className="formbold-form-label"
                        >
                          Location
                        </label>
                        <br></br>

                        <select
                          className="form-control"
                          name="location"
                          value={location_id}
                          onChange={(e) => set_location_id(e.target.value)}
                        >
                          <option value={""}>Choose</option>
                          {allLocation?.map((e) => {
                            return (
                              <option value={e?.id}>{e?.eventloc_name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <div>
                          <label
                            htmlFor="start_date"
                            className="formbold-form-label"
                          >
                            Date
                          </label>
                          <input
                            type="date"
                            name="start_date"
                            className="form-control"
                            value={formatDate(start_date)}
                            onChange={(e) =>
                              set_start_date(formatDate(e.target.value))
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="address"
                          className="formbold-form-label"
                        >
                          Time
                        </label>
                        <br></br>

                        <input
                          type="time"
                          name="time"
                          id="time"
                          className="form-control"
                          value={start_time}
                          onChange={(e) => set_start_time(e.target.value)}
                          required
                        />
                        {errors.start_time && <small className="input-error-message">{errors.start_time[0]}</small>}
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div style={{ position: "relative" }}>
                        <label
                            htmlFor="address"
                            className="formbold-form-label"
                          >
                            Duration for event
                          </label>
                          <br></br>

                          <input
                            type="text"
                            name="duration"
                            className="form-control"
                            placeholder=""
                            value={duration}
                            onChange={(e) => {
                              calculateEndDateTimeByDuration(
                                e.target.value != "" ? e.target.value : 0
                              );
                              setDuration(e.target.value);
                            }}
                            // style={{ paddingRight: "50px" }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "60%",
                              // transform: "translateY(-120%)",
                              // pointerEvents: "none",
                              
                            }}
                          >
                            Minutes
                          </span>
                          {errors.end_time && <small className="input-error-message">{errors.end_time[0]}</small>}
                      </div>
                      <div>
                        <div
                          className="preference"
                          style={{ fontSize: "15px" }}
                        >
                          <input
                            type="checkbox"
                            name="all_day"
                            value="All day"
                            checked={event_all_day}
                            onChange={(e) =>
                              set_event_all_day(e.target.checked)
                            }
                          />
                          All Day
                        </div>
                      </div>
                    </div>
                    
                    <div className="formbold-input-flex">
                      <div>
                        <div
                          className="preference"
                          style={{ fontSize: "15px" }}
                        >
                          <input
                            type="checkbox"
                            name="event_repeats"
                            value="This event repeats"
                            checked={event_recurring}
                            onChange={(e) =>
                              set_event_recurring(e.target.checked)
                            }
                          />
                          This event repeats
                        </div>
                      </div>
                    </div>
                    {event_recurring ?
                        <div className="recurring">
                          <div className="recurring-head">
                            <i class="fa fa-undo" aria-hidden="true"></i>{" "}
                            <strong>Recurring Event</strong>
                          </div>

                          <div className="formbold-input-flex diff">
                            <div>
                              <div>
                                <label
                                  htmlFor="frequency"
                                  className="formbold-form-label"
                                >
                                  Frequency
                                </label>
                              </div>
                              <div className="input-radio">
                                <input
                                  type="radio"
                                  value="Daily"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Daily"}
                                ></input>
                                Daily
                                <input
                                  type="radio"
                                  value="Weekly"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Weekly"}
                                ></input>
                                Weekly
                                <input
                                  type="radio"
                                  value="Monthly"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Monthly"}
                                ></input>
                                Monthly
                                <input
                                  type="radio"
                                  value="Yearly"
                                  name="frequency"
                                  onChange={(e) =>
                                  set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Yearly"}
                                ></input>
                                Yearly
                              </div>
                              {event_frequency == "Daily" && (
                                <DayTabInput
                                  values={event_repeat_on}
                                  setDaysValue={set_event_repeat_on}
                                />
                              )}
                            </div>
                          </div>
                          {event_frequency != "Daily" ??
                            <div className="formbold-input-flex align-items-end">
                              <div>
                                <label
                                  htmlFor="time"
                                  className="formbold-form-label"
                                >
                                  Every
                                </label>
                                <br></br>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    name="every"
                                    className="form-control"
                                    style={{
                                      paddingLeft: "25px",
                                      paddingRight: "70px",
                                    }}
                                    value={event_frequency_val}
                                    min={1}
                                    max={100}
                                    onChange={(e) =>
                                      set_event_frequency_val(e.target.value)
                                    }
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                    }}
                                  >
                                    {event_frequency === "Daily" ||
                                    event_frequency === "Weekly"
                                      ? "Weeks"
                                      : event_frequency}
                                  </span>
                                </div>
                              </div>
                            </div>
                          }
                          {!event_repeat_indefinitely ?? 
                            <div>
                              <label
                                htmlFor="time"
                                className="formbold-form-label"
                              >
                                Repeat Until
                              </label>
                              <br></br>
                              <input
                                type="date"
                                name="repeat_until"
                                className="form-control"
                                value={event_repeat_until}
                                onChange={(e) =>
                                  set_event_repeat_until(
                                    formatDate(e.target.value)
                                  )
                                }
                              />{" "}
                            </div>
                          }

                          <div className="formbold-input-flex">
                            <div>
                              <div
                                className="preference"
                                style={{ fontSize: "15px" }}
                              >
                                <input
                                  type="checkbox"
                                  name="repeats_indefinitely"
                                  value=""
                                  onChange={(e) =>
                                    set_event_repeat_indefinitely(
                                      e.target.checked
                                    )
                                  }
                                  checked={event_repeat_indefinitely}
                                />
                                Repeat indefinitely
                              </div>
                            </div>
                          </div>
                        </div>
                     : ''}
                    <div className="formbold-input-flex diff">
                      <div>
                        <div>
                          <label
                            htmlFor="student_pricing"
                            className="formbold-form-label"
                          >
                            Student Pricing
                          </label>
                        </div>
                        <div className="input-radio">
                          <input
                            type="radio"
                            value="default"
                            name="student_pricing"
                            checked={
                              student_pricing == "default" ? true : false
                            }
                            onChange={(e) =>
                              set_student_pricing(e.target.value)
                            }
                          ></input>
                          Student Default
                        </div>
                        <div className="input-radio">
                          <input
                            type="radio"
                            value="no_charge"
                            name="student_pricing"
                            checked={
                              student_pricing == "no_charge" ? true : false
                            }
                            onChange={(e) =>
                              set_student_pricing(e.target.value)
                            }
                          ></input>
                          No charge {selectedCurrency} 0.00
                        </div>
                        <div className="input-radio">
                          <input
                            type="radio"
                            value="specific"
                            name="student_pricing"
                            checked={
                              student_pricing == "specific" ? true : false
                            }
                            onChange={(e) =>
                              set_student_pricing(e.target.value)
                            }
                          ></input>
                          Specify price per student
                        </div>
                      </div>
                    </div>
                    {student_pricing == "specific" && (
                      <div className="formbold-input-flex diff">
                        <div>
                          <div>
                            <label
                              htmlFor="student_pricing"
                              className="formbold-form-label"
                            >
                              Pricing
                            </label>
                          </div>
                          <div className="input-radio">
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span className="input-group-text" style={{ height: '100%' }}>{selectedCurrency}</span> {/* Currency symbol */}
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={student_pricing_val}
                                  name="student_pricing_val"
                                  onChange={(e) => set_student_pricing_val(e.target.value)}
                                />
                              </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="formbold-input-flex diff">
                      <div>
                        <label
                          htmlFor="public_desc"
                          className="formbold-form-label"
                        >
                          Public Description <span>Optional</span>
                        </label>

                        <textarea
                          name="public_desc"
                          className="form-control"
                          value={event_public_desc}
                          onChange={(e) =>
                            set_event_public_desc(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="formbold-input-flex diff">
                      <div>
                        <label
                          htmlFor="private_desc"
                          className="formbold-form-label"
                        >
                          Private Description <span>Optional</span>
                        </label>
                        <br></br>
                        <span>
                          Visible only to you, even if the event is public
                        </span>
                        <textarea
                          name="private_desc"
                          className="form-control"
                          value={event_private_desc}
                          onChange={(e) =>
                            set_event_private_desc(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="formbold-form-btn-wrapper">
                  <div className="btn-end">
                    <Link className="cancel" onClick={closeModal}>
                      Cancel
                    </Link>
                    {isEditForm && event_recurring && (                          
                      <button
                        className="cancel"
                        onClick={(e) => saveAllEvents(e)}
                      >
                        Save This & Future Events
                      </button>
                    )}
                    <button
                      className="formbold-btn"
                      onClick={(e) => saveOneEvents(e)}
                    >
                      {event_recurring ? "Save This Event" : "Save" }
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
    </ReactModal>
)